import React from 'react'
import {RecordPage} from '../Records';

export default function NewRecordPage() {
    return (<RecordPage/>);
}




