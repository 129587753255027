import React, {Component} from 'react';
import {compose} from 'recompose';
import {withFirebase} from "../Firebase";
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';

import {AuthUserContext} from "../Session";

import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {GridList} from "@material-ui/core";
import GridListTile from "@material-ui/core/GridListTile";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as ROUTES from "../../constants/routes";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import withWidth from "@material-ui/core/withWidth";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(4),
    },
    spinnerContainer: {
        width: '50px',
        margin: '2em auto',
    },
    loading: {
        margin: '2em auto',
    },
    moreButton: {
        margin: theme.spacing(3, 0, 0),
        textAlign: 'center',
    },
    titleContainer: {
        margin: theme.spacing(4, 0)
    }
});

class CampsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            camps: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.camps()
            .on('value', snapshot => {
                const campsObject = snapshot.val();

                if (campsObject === null) {
                    this.setState({camps: []});
                    return;
                }

                const campsList = Object.keys(campsObject).map(key => ({
                    ...campsObject[key],
                    uid: key,
                }));
                campsList.sort((a, b) => b.year - a.year);
                this.setState({
                    camps: campsList,
                    loading: false,
                })
            });
    }

    componentWillUnmount() {
        this.props.firebase.camps().off();
    }

    render() {
        const {classes, width} = this.props;
        const {camps, loading} = this.state;

        const cellHeight = {
            xs: 80,
            sm: 100,
            md: 150,
            lg: 180,
            xl: 210,
        }[width];

        return (
            <AuthUserContext.Consumer>
                {authUser => {
                    return (
                        <Container maxWidth="md" className={classes.root}>
                            <div className={classes.titleContainer}>
                                <Typography variant='h2' align='center'>
                                    Materiale fra lejrene
                                </Typography>
                                { !authUser &&
                                    <Typography variant='subtitle1' align='center'>
                                        Log ind med informationerne fra forældremødet for at se mere.
                                    </Typography>
                                }
                            </div>
                            {loading &&
                            <div className={classes.spinnerContainer}>
                                <CircularProgress className={classes.loading}/>
                            </div>
                            }
                            {!loading && camps && camps.map((camp, idx) => {
                                const cols = 3;
                                let missing = camp.content !== undefined ? (camp.content.length % cols === 0 ? 0 : cols - camp.content.length % cols) : 0;
                                return (
                                    <Grid container spacing={5} key={camp.uid}>
                                        <Grid item sm={12}>
                                            <Paper className={classes.paper}>
                                                <Grid container spacing={2}>
                                                    <Grid item sm={12} md={12}>
                                                        <Typography variant="h4" classes={classes.title}>
                                                            <Hidden xsDown>Gymnastiklejr</Hidden> {camp.year} - Uge {camp.week}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <GridList cellHeight={cellHeight} cols={cols}>
                                                    {camp.content !== undefined && camp.content.map((img, imgIdx) => {
                                                        return (
                                                            <GridListTile
                                                                cols={imgIdx === camp.content.length - 1 ? 1 + missing : 1}
                                                                key={imgIdx}>
                                                                <img src={img} alt="Camp activity"/>
                                                            </GridListTile>
                                                        );
                                                    })}
                                                </GridList>
                                                <div sm={12} md={12} className={classes.moreButton}>
                                                    {authUser !== null && camp.uid in authUser.roles && (
                                                        <Button variant="contained" color="secondary" size="large"
                                                                onClick={() => this.props.history.push(`${ROUTES.CAMPS}/${camp.uid}`)}>
                                                            Se mere
                                                            <KeyboardArrowRightIcon/>
                                                        </Button>
                                                    )}
                                                </div>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Container>
                    )
                }}
            </AuthUserContext.Consumer>
        )
    }
}

export default compose(withWidth(), withFirebase, withStyles(styles), withRouter)(CampsPage);
