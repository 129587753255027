import React, {Component} from 'react';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core/styles';
import {withFirebase} from '../../Firebase';

import CampEdit from './campEdit';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import {INITIALIZE_CAMP} from '../../../constants/objects';
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    progres: {
        margin: theme.spacing(2),
    },
    collapse: {
        backgroundColor: theme.palette.background.list,
        borderBottom: '1px solid #aaa',
        borderTop: '1px solid #aaa',
        paddingBottom: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        border: 'none',
        outline: 'none',
    },
    innerPaper: {
        width: 300,
        backgroundColor: '#f9f9f9',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        outline: 'none',
        position: 'relative',
        left: '-50%',
        bottom: '10vh',
        borderRadius: 4,
    },
    leftButton: {
        marginRight: theme.spacing(1),
    },
    warningButton: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    }
});


class CampsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            camps: [],
            open: -1,
            confirmDelete: '',
            loading: true,
            adding: false,
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.camps()
            .on('value', snapshot => {
                const campsObject = snapshot.val();

                if (campsObject === null) {
                    this.setState({camps: []});
                    return;
                }

                const campsList = Object.keys(campsObject).map(key => ({
                    ...campsObject[key],
                    uid: key,
                }));
                campsList.sort((a, b) => b.year - a.year);

                this.setState({
                    camps: campsList,
                    loading: false,
                })
            });
    }

    componentWillUnmount() {
        this.props.firebase.camps().off();
    }

    render() {
        const {classes} = this.props;
        const {camps, open, adding, confirmDelete} = this.state;

        const handleClick = uid => () => {
            if (uid === this.state.open) {
                this.setState({open: -1});
            } else {
                this.setState({open: uid});
            }
        };

        const handleDelete = uid => () => {
            // TODO remove element from firebase.
            // Maybe even add a confirm box.
            //
            this.setState({confirmDelete: uid})
        }

        const handleAdd = () => {
            this.setState({adding: true,});
            const campRef = this.props.firebase.camps().push();

            campRef.set({...INITIALIZE_CAMP})
                .then(() => {
                    this.setState({adding: false});
                })
                .catch((error) => {
                    this.setState({error, adding: false});
                });
        }

        return (
            <React.Fragment>
                <List>
                    {camps && camps.map((camp, idx) => (
                        <React.Fragment key={camp.uid}>
                            {idx === 0 ? null : <Divider/>}
                            <ListItem button onClick={handleClick(camp.uid)} className={classes.listItem}>
                                <ListItemIcon>
                                    {open === idx ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={camp.year + " - " + camp.week}/>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Delete" onClick={handleDelete(camp.uid)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse in={open === camp.uid} timeout="auto" className={classes.collapse} unmountOnExit>
                                <CampEdit uid={camp.uid}/>
                            </Collapse>
                            <Modal
                                aria-labelledby="Confirm delete"
                                aria-describedby="Confirm delete of camp"
                                open={confirmDelete === camp.uid}
                                onClose={() => {
                                    this.setState({confirmDelete: ''})
                                }}>
                                <div className={classes.paper}>
                                    <div className={classes.innerPaper}>
                                        <h2 id="modal-title">Vil du slette campen?</h2>
                                        <p id="simple-modal-description">
                                            Er du sikker på at du vil slette campen?
                                        </p>
                                        <Button variant="contained" onClick={() => {
                                            this.props.firebase.camp(camp.uid).remove()
                                        }} className={classes.warningButton}>Slet</Button>
                                    </div>
                                </div>
                            </Modal>
                        </React.Fragment>
                    ))}
                </List>
                {adding ? <CircularProgress className={classes.progress}/> :
                    <Button onClick={handleAdd} color="primary">
                        <AddIcon className={classes.leftButton}/>
                        Tilføj lejr
                    </Button>
                }

            </React.Fragment>
        );
    }
}

export default compose(withFirebase, withStyles(styles))(CampsList);
