import React, {Component} from 'react';
import clsx from 'clsx';
import {compose} from 'recompose';
import {
    Container,
    Grid,
    Link,
    Typography,
    GridList,
    GridListTile,
    Card,
    CardContent,
    CardMedia,
    Button,
    withWidth,
    withStyles
} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative',
        marginBottom: theme.spacing(3),
    },
    headImageBackground: {
        zIndex: 10,
    },
    headImageTitle: {
        zIndex: 1,
        position: 'absolute',
        top: '36%',
        left: 0,
        width: '100%',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.opaque,
        padding: theme.spacing(2),
    },
    title: {
        padding: theme.spacing(2),
        color: theme.palette.primary.contrastText,
    },
    xsTitle: {fontSize: 30},
    smTitle: {fontSize: 50},
    mdTitle: {fontSize: 60},
    lgTitle: {fontSize: 100},
    xlTitle: {fontSize: 200},

    xsSubTitle: {fontSize: 30},
    smSubTitle: {fontSize: 40},
    mdSubTitle: {fontSize: 50},
    lgSubTitle: {fontSize: 60},
    xlSubTitle: {fontSize: 70},

    card: {
        background: '#fff',
    },
    cardMedia: {
        height: 160,
    },
    gudenaadalens: {
        backgroundSize: 'contain',
    },
    videoContainer: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
    },
    videoFrame: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }
});

const urls = [
    {
        url: process.env.PUBLIC_URL + 'images/skrue.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/gruppe.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/disco.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/guide.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/dans.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/rondat.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/rytme.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/spas.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/buk.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/ude.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/ring.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
    {
        url: process.env.PUBLIC_URL + 'images/glidebane.jpg',
        cols: {xl: 2, lg: 2, md: 2, sm: 2, xs: 2,},
        rows: {xl: 2, lg: 2, md: 1, sm: 1, xs: 1,}
    },
];

class Landing extends Component {
    render() {
        const {classes} = this.props;
        const {width} = this.props;

        const signUpAction = () => {
        	window.location.href = "https://www.dgi.dk/gymnastik/arrangementer/202309556002";

            //alert("Tilmeldingen er pt. ikke åben.")
        };
        // <Grid item xs={12} sm={6} lg={3}>
          //  <Card className={classes.card}>
           //     <CardMedia
            //        className={clsx(classes.gudenaadalens, classes.cardMedia)}
             //       image={process.env.PUBLIC_URL + 'images/gudenaadalens.jpg'}
              //      title="Gudenådalens Efterskole"/>
               // <CardContent>
                //    <Typography gutterBottom variant="h5" component="h2">
                 //       Faciliteter
                  //  </Typography>
                   // <Typography variant="body2" color="textSecondary" component="p">
                    //    Gymnastiklejr bliver afholdt på <Link href="http://www.gudenaadalen.dk" target="_blank">Gudenådalens Efterskole</Link>.
                     //   Der er fantastiske faciliteter, som fx. store haller (inklusiv et springcenter)
                        //og smukke udeområder.
                    //</Typography>
                //</CardContent>
            //</Card>
        //</Grid>

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <div className={classes.headImageBackground}>
                        <GridList cols={8}>
                            {urls.map((tile, idx) => (
                                <GridListTile key={idx} cols={tile.cols[width]} rows={tile.rows ? tile.rows[width] : 1}>
                                    <img alt="Aktivitet fra gymnastiklejr" src={tile.url}/>
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                    <div className={classes.headImageTitle}>
                        <Typography variant="h1" className={clsx(classes.title, classes[`${width}Title`])}>
                            Gymnastiklejr!
                        </Typography>
                        <Button variant='contained' color='secondary' size='large' onClick={signUpAction}>Gå til tilmelding!</Button>
                    </div>
                </div>
                <Container maxWidth='lg'>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Typography variant="h2" align="center" className={classes[`${width}SubTitle`]}>
                                Lidt om Gymnastiklejr
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={process.env.PUBLIC_URL + 'images/flik.jpg'}
                                    title="Gymnastikken er i centrum hver eneste dag på gymnastiklejr!"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Gymnastikken
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        På gymnastiklejr får man en alle tiders chance for at fordybe sig i
                                        gymnastikken, hvad end man er til rytme, spring eller begge dele.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={process.env.PUBLIC_URL + 'images/leg.jpg'}
                                    title="Hver dag er en leg!"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Det sociale
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        På gymnastiklejr bliver der gjort meget ud af, at alle skal have det
                                        sjovt. Hvad end man er kommet på gymnastiklejr selv eller sammen med
                                        sine venner.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={process.env.PUBLIC_URL + 'images/tosse.jpg'}
                                    title="Instruktørene er dygtige men tossede! :-)"/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Instruktører
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Vi gør meget ud af at have en trænerflok, som er yderst kompetente,
                                        gode til børn og sprængfyldte med godt humør.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card className={classes.card}>
                                <CardMedia
                                    className={clsx(classes.cardMedia)}
                                    image={process.env.PUBLIC_URL + 'images/andet.jpg'}
                                    title="En af mange alternative aktiviteter."/>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Alt det andet
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Vi har også lavet meget andet end gymnastik på lejrene. For at nævne nogle få:
                                        kartoffelkanoner, glidebaner, forhindringsløb, vandaktiviteter, etc.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.card}>
                                <div className={classes.videoContainer}>
                                    <iframe title="YouTube frame"
                                            className={classes.videoFrame}
                                            src="https://www.youtube.com/embed/vpXN7-x9lgA"
                                            frameBorder="0"
                                            allowFullScreen
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Smagsprøve #1
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Få en smagsprøve på alt det fede der sker på Gymnastiklejr.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.card}>
                                <div className={classes.videoContainer}>
                                    <iframe title="YouTube frame"
                                            className={classes.videoFrame}
                                            src="https://www.youtube.com/embed/x7pJqM-skK0"
                                            frameBorder="0"
                                            allowFullScreen
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Smagsprøve #2
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Var det noget med en mere...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

export default compose(withWidth(), withStyles(styles))(Landing);
