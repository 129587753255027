export const LANDING            = '/';
export const SIGN_UP            = '/signup';
export const SIGN_IN            = '/signin';
export const HOME               = '/home';
export const ACCOUNT            = '/account';
export const ADMIN              = '/admin';
export const PASSWORD_FORGET    = '/pw-forget';
export const CONTACT            = '/contact';
export const RECORDS            = '/records';
export const NEW_RECORD         = '/new-records';
export const CAMPS              = '/lejre';
