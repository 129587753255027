import React, {Component} from 'react';
import {compose} from 'recompose';
import {withStyles, makeStyles, createStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const elementStyles = theme => ({
    collapse: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    badgePadding: {
        margin: theme.spacing(0,2),
    },
    invisible: {
        display: 'none',
    }
});
const useStyles = makeStyles(theme => createStyles(elementStyles(theme)));

const VariantListElement = ({url, name, ts, variant, deleteAction}) => {
    const classes = useStyles();

    const date = new Date(ts);
    const yesterday = new Date(Date.now() - 1000*60*60*36 );
    const showNew = date > yesterday;

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    if (variant === 'youtube') {
    	if (url.includes("watch?v=")) { url = url.replace("watch?v=", "embed/"); } // Fix copy paste link
        return (
            <React.Fragment>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </ListItemIcon>
                        <ListItemText primary={name}/>
                    { deleteAction !== undefined && (
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="Delete" onClick={deleteAction}>
                                    <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                    { deleteAction === undefined && showNew && (
                        <Tooltip title={date.toLocaleString()}>
                            <Badge badgeContent="Ny!" color="secondary" className={classes.badgePadding}>
                                <AddIcon className={classes.invisible}/>
                            </Badge>
                        </Tooltip>
                    )}
                </ListItem>
                <Collapse in={open} timeout="auto" className={classes.collapse} unmountOnExit>
                    <iframe title="YouTube frame"
                            className={classes.video}
                            src={url}
                            frameBorder="0"
                            allowFullScreen
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </Collapse>
            </React.Fragment>
        );
    } else if (variant === 'photos') {
        return (
            <ListItem button component="a" href={url} target="_blank">
                <ListItemIcon>
                    <PhotoAlbumIcon/>
                </ListItemIcon>

                <ListItemText primary={name}/>

                { deleteAction !== undefined && (
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete" onClick={deleteAction}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
                { deleteAction === undefined && showNew && (
                    <Tooltip title={date.toLocaleString()}>
                        <Badge badgeContent="Ny!" color="secondary" className={classes.badgePadding}>
                            <AddIcon className={classes.invisible}/>
                        </Badge>
                    </Tooltip>
                )}
            </ListItem>
        );
    } else {
        return null;
    }
};

export { VariantListElement };

const styles = theme => ({
    leftButton: {
        marginRight: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(2),
    },
});

class DisplayUrls extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: '',
            name: '',
            urls: this.props.urls,
        }
    }

    render() {
        const {onListChanged, classes} = this.props;
        const {urls, name, url} = this.state;

        const onFieldChanged = (event) => {
            this.setState({[event.target.name]: event.target.value});
        };

        const addNewEntry = () => {
            if (this.state.url === '' || this.state.name === '') {
                return;
            }
            const {url, urls} = this.state;
            urls.push({name, url, ts: Date()});
            this.setState({urls, name: '', url: ''});
            onListChanged(urls);
        };

        const deleteEntry = idx => () => {
            const {urls} = this.state;
            urls.splice(idx, 1);
            this.setState({urls});
            onListChanged(urls);
        };

        return (
            <React.Fragment>
                <List aria-label="List Image Urls">
                    {urls && urls.map((comp, idx) => (
                        <VariantListElement key={idx} deleteAction={deleteEntry(idx)} {...this.props} {...comp} />
                    ))}
                </List>

                <TextField
                    id="newUrlField"
                    name="url"
                    type="text"
                    label="URL"
                    value={url}
                    className={classes.textField}
                    onChange={onFieldChanged}
                />
                <TextField
                    id="newNameField"
                    name="name"
                    type="text"
                    label="Navn"
                    value={name}
                    className={classes.textField}
                    onChange={onFieldChanged}
                />

                <Button color="primary" onClick={addNewEntry}>
                    <AddIcon className={classes.leftButton}/>
                    Tilføj
                </Button>
            </React.Fragment>
        );
    }
}

export default compose(withStyles(styles))(DisplayUrls);
