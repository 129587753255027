import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles'
import {withFirebase} from "../Firebase";
import {compose} from 'recompose';
import MaterialTable from 'material-table'
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import {AuthUserContext} from '../Session';
import Typography from '@material-ui/core/Typography';

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import HolderList from './holderList'

import TextTruncate from 'react-text-truncate';
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    helpIcon: {
        color: theme.palette.grey["400"],
    },
    paper: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        border: 'none',
        outline: 'none',
    },
    innerPaper: {
        width: 300,
        backgroundColor: '#f9f9f9',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        outline: 'none',
        position: 'relative',
        left: '-50%',
        bottom: '10vh',
        borderRadius: 4,
    },
    warningButton: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    }
});

class RecordList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            records: [],
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.records().on('value', snapshot => {
            const recordsObj = snapshot.val();
            const recordsList = Object.keys(recordsObj).map(key => ({
                ...recordsObj[key],
                uid: key,
            }));
            this.setState({
                records: recordsList,
                loading: false,
            })
        });
    }

    componentWillUnmount() {
        this.props.firebase.records().off();
    }

    render() {
        const {records, loading} = this.state;
        return (
            <div>
                {loading && (<div>
                    <Typography variant="overline" display="block">
                        Loading...
                    </Typography>
                </div>)}
                {!loading && <RList records={records}/>}
            </div>
        );
    }
}

class RListBase extends Component {
    constructor(props){
        super(props);
        this.state = {
            deleteId: '',
        }
    }
    render() {
        const {classes} = this.props;
        const {records} = this.props;
        return (
            <div style={{maxWidth: "100%"}}>
                <AuthUserContext.Consumer>
                    {authUser => {
                        // Available actions in the record list. Should be empty it admin is not logged ind.
                        const getActions = () => {
                            const showConfirmAction = (event, rowData) => {
                                this.setState({deleteId: rowData.uid});
                            };
                            return authUser && !!authUser.roles[ROLES.ADMIN] ?
                                [
                                    {
                                        icon: 'delete',
                                        tooltip: 'Delete Record',
                                        onClick: showConfirmAction,
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit record',
                                        onClick: (event, rowData) => {
                                            this.props.history.push(ROUTES.RECORDS + '/' + rowData.uid);
                                        }
                                    }
                                ] : [];
                        };
                        const deleteAction = () => {
                            // Do save operation
                            const {deleteId: uid} = this.state;
                            this.props.firebase.record(uid).remove();
                            this.setState({deleteId: ''});
                        };
                        return (
                            <React.Fragment>
                                <Modal
                                    aria-labelledby="Confirm delete"
                                    aria-describedby="Confirm delete of record"
                                    open={this.state.deleteId !== ''}
                                    onClose={() => { this.setState({deleteId: ''}) }}>
                                    <div className={classes.paper}>
                                        <div className={classes.innerPaper}>
                                            <h2 id="modal-title">Vil du slette rekorden?</h2>
                                            <p id="simple-modal-description">
                                                Er du sikker på at du vil slette rekorden?
                                            </p>
                                            <Button variant="contained" onClick={deleteAction} className={classes.warningButton}>Slet</Button>
                                        </div>
                                    </div>
                                </Modal>
                                <MaterialTable
                                    title="Rekorder"
                                    columns={
                                        [
                                            {
                                                title: 'Titel', field: 'name', render: rowData => {
                                                    return (<span>{rowData.name && (rowData.name)}
                                                        <small>{rowData.note && (
                                                            <Tooltip title={rowData.note}><HelpIcon position="right"
                                                                                                    fontSize="small"
                                                                                                    className={classes.helpIcon}/></Tooltip>)}</small></span>)
                                                }
                                            },
                                            {
                                                title: "Rekordholdere", field: "holders[0].names", render: rowData => {
                                                    return rowData.holders && rowData.holders.length > 0 && rowData.holders[0].names && (
                                                        <TextTruncate truncateText="..." text={rowData.holders[0].names}/>)
                                                }
                                            },
                                            {title: "Rekord", field: "holders[0].amount"},
                                        ]}
                                    data={records}
                                    actions={getActions()}
                                    detailPanel={rowData => {
                                        const {holders} = rowData;
                                        return (<HolderList holders={holders}/>);
                                    }}
                                    options={{pageSize: 10, pageSizeOptions: [10, 20, records.length]}}
                                />
                            </React.Fragment>
                        )
                    }}
                </AuthUserContext.Consumer>
            </div>);
    }
}

const RList = compose(withRouter, withFirebase, withStyles(styles))(RListBase);

export default compose(withFirebase, withStyles(styles))(RecordList);
