import React, {Component} from 'react';
import {compose} from 'recompose';
import {withStyles} from '@material-ui/core/styles';
import {withFirebase} from '../Firebase';
import PropTypes from 'prop-types';
import uuid from 'uuid';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
	root: {
		magrin: theme.spacing(2),
		position: 'relative',
		minHeight: theme.spacing(10),
	},
	card: {
		minWidth: 200,
		maxWidth: 200,
		display: 'inline-block',
	},
	cardMedia: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	deleteButton: {
		marginLeft: 'auto',
	},
	addButton: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	addFileField: {
		minWidth: 200,
		minHeight: 200,
		background: 'grey',
	},
	button: {
		margin: 12,
	},
	exampleImageInput: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		opacity: 0,
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	titleBar: {
		background:
		  'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
		  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	icon: {
		color: 'white',
	},
	progress: {
		margin: 'auto',
	},
	input: {
		display: 'none',
	},
});

class ImageUploader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			urls: this.props.urls,
			progress: {},
		};
		this.onFilesInputChange = this.onFilesInputChange.bind(this);

	}

	onFilesInputChange(event) {
		if (event.target.files === undefined || event.target.files.length === 0) { 
			console.log("Empty file event");
			return;
		}
		const { progress } = this.state;

		const files = event.target.files;
		Object.keys(files).forEach(key => {
			const file = files[key];
			const file_types = {
				'image/jpeg': '.jpg',
				'image/png': '.png'
			}
			if (!(file.type in file_types)) {
				// TODO make alert
				return;
			}

			const ending = file_types[file.type];
			const rand_filename = uuid.v1();
			progress[rand_filename] = 0;
			this.setState( { progress } );
			
			const file_name = `${rand_filename}${ending}`
			const img_ref = this.props.firebase.public_image(file_name);
			const uploadTask = img_ref.put(file)
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// State changed
					progress[rand_filename] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState( { progress } );
				},
				(error) => {
					// Error
					console.log(error);
				},
				() => {
					// On complete
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						const { urls } = this.state;
						urls.push(downloadURL);
						this.setState( { urls } );
						this.props.onListChange(urls);

						delete progress[rand_filename];
						this.setState( { progress } );
					});
				} 
			);
		});
	}

	render() {
		const { classes } = this.props;
		const { urls, progress } = this.state;

		const deleteContentButton = idx => () => {
			const { urls } = this.state;
			urls.splice(idx, 1);
			this.setState( [ urls ] );
			this.props.onListChange(urls);
		};

		return (
			<Grid container spacing={3} className={classes.root}>
				<Grid item sm={12}>
					<Typography variant="subtitle1" component="h5">
						Offentlige billeder
					</Typography>
				</Grid>
				<Grid item sm={12}>
				  <GridList cellHeight={160} className={classes.gridList} cols={3}>
					{ urls && urls.map( (url, idx) => (
					  <GridListTile key={url} cols={1}>
						<img src={url} alt="Activity from gymnastics camp" />
						<GridListTileBar
						  titlePosition="bottom"
						  actionIcon={
							<IconButton className={classes.icon} onClick={deleteContentButton(idx)}>
							  <DeleteIcon />
							</IconButton>
						  }
						  actionPosition="left"
						  className={classes.titleBar}
						/>
					  </GridListTile>
					))}
					{ progress && Object.keys(progress).map( (key, idx) => (
						<GridListTile key={key} cols={1}>
							<CircularProgress className={classes.progress} variant="static" value={progress[key]} />
						</GridListTile>
					))}
					<GridListTile cols={1}>
						<input
							accept="image/*"
							className={classes.input}
							onChange={this.onFilesInputChange}
							id="contained-button-file"
							multiple
							type="file" />
						<label htmlFor="contained-button-file" className={classes.addButton}>
							<Button component="span" color="primary" className={classes.button}>
								Upload
								<CloudUploadIcon  className={classes.rightIcon} />
							</Button>
						</label>
					</GridListTile>
				  </GridList>
				</Grid>
			</Grid>
		)
	}

}

ImageUploader.propTypes = {
    onListChange: PropTypes.func.isRequired,
    urls: PropTypes.array.isRequired,
};

export default compose(withFirebase, withStyles(styles))(ImageUploader);
