import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";

const HolderList = ({holders, onHolderDeleted, editable}) => {
    return (
        <List>
            {
                holders && holders.map((holder, idx) =>
                    (
                        <ListItem key={idx}>
                            <ListItemIcon>
                                <StarBorderIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={holder.names}
                                secondary={
                                    (<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {holder.date}
                                        </Typography>
                                        {" - " + holder.amount}
                                    </React.Fragment>)
                                }
                            />
                            {editable && (
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Delete" onClick={onHolderDeleted(idx)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))
            }
        </List>
    )
};

export default HolderList

