import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";
import {VariantListElement} from "../../DisplayUrls";
import Remarkable from "remarkable";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
    },
    markdownContent: {
        margin: theme.spacing(3),
    }
});

const useStyles = makeStyles(theme => createStyles(styles(theme)));

const Camp = ({camp}) => {
    const classes = useStyles();
    const cols = 3;

    const lastSize = (camp.content === undefined || camp.content.length % cols === 0) ? 0 : cols - camp.content.length % cols;

    const getRawMarkup = () => {
        const md = new Remarkable();
        return {__html: md.render(camp.text)};
    };

    return (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12}>
                <Typography variant="h4" align="center">
                    Gymnastiklejr {camp.year} - Uge {camp.week}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <GridList cols={cols}>
                    {camp.content && camp.content.map((url, idx) => (
                        <GridListTile key={idx} cols={idx === camp.content.length - 1 ? 1 + lastSize : 1}>
                            <img src={url} alt="Activity from the camp"/>
                        </GridListTile>
                    ))}
                </GridList>
            </Grid>
            {camp.text &&
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <div
                        className={classes.markdownContent}
                        dangerouslySetInnerHTML={getRawMarkup()}
                    />
                </Paper>
            </Grid>
            }
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography variant="h5" display="block">
                        Billeder fra ugen
                    </Typography>
                    <List component="ul">
                        {camp.albums && camp.albums.map((album, idx) => (
                            <VariantListElement key={idx} variant="photos" {...album} />
                        ))}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography variant="h5" display="block">
                        Video fra ugen
                    </Typography>
                    <List component="ul">
                        {camp.videos && camp.videos.map((video, idx) => (
                            <VariantListElement key={idx} variant="youtube" {...video} />
                        ))}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    )
};

export default Camp;
