
export const INITIAL_RECORD_STATE = {
    name: "",
    note: "",
    holders: [],
    error: null,
};

export const INITIALIZE_CAMP = {
    year: new Date().getFullYear(),
    week: 27,
    albums: [],
    videos: [],
    content: [],
};
