import React, {Component} from 'react';
import {compose} from 'recompose';

import {Link} from 'react-router-dom';

import {withFirebase} from "../Firebase";
import {withAuthorization} from '../Session';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";

import UserList from './Users/userList';
import CampsList from './Camp/campsList';
import RecordsButton from './Records/RecordsButton';

import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import SplitCampBtn from "./Camp/splitCampBtn";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3,2),
    },
});

class AdminPage extends Component {
	componentDidMount() {
		this.setState({loading: true});
		this.props.firebase.users().on('value', snapshot => {
			const usersObject = snapshot.val();
			const userList = Object.keys(usersObject).map(key => ({
				...usersObject[key],
				uid: key,
			}));
			this.setState({
				users: userList,
				loading: false,
			})
		});
	}

	componentWillUnmount() {
		this.props.firebase.users().off();
	}

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth="lg" className={classes.root}>
                <Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography variant="h5" component="h3">
								Brugere
							</Typography>
							<UserList />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography variant="h5" component="h3">
								Gymnastiklejre
							</Typography>
							<CampsList />
						</Paper>
					</Grid>
					<SplitCampBtn/>
					<RecordsButton />
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography variant="h5" component="h3">
								Hjælp til Selvhjælp
							</Typography>

								Det meste skulle gerne give sig selv. Der er dog et par tips og tricks, som kunne
								hjælpe, hvis noget bøvler.
								<ol>
									<li>
										Når du har redigeret en lejr, så <b>HUSK AT TRYKKE GEM I TOPPEN.</b> Før slår
										ændringerne ikke igennem.
									</li>
									<li><b>Nye brugere</b> For at tilføje en ny bruger, skal man gå til <Link
										to={ROUTES.SIGN_UP}>Signup siden</Link>. Efterfølgende skal man bruge en admin
										bruger til at give privilegier til hvilke lejre man kan se informationer fra.
									</li>
									<li>
										Har man glemt sin kode til login, så kan man få tilsendt en ny via <Link to={ROUTES.PASSWORD_FORGET}>dette link</Link>.
									</li>
									<li>
										Med både videoer og billeder er det meningen, at links skal være private og kun
										kunne findes via link. På den måde sørger vi for at overholde GDPR.
									</li>
									<li>
										Hvis det hele bryder ned, eller der er andre problemer, som i ikke selv kan løse, så ring til Frederik på <a href="tel:61660123">61660123</a>.
									</li>
									<li> 
										Hvis YouTube url ikke virker, så kopier link fra "Iframe embedding" på YouTube istedet.
									</li>
								</ol>
						</Paper>
					</Grid>
                </Grid>
            </Container>
        );
    }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(withAuthorization(condition), withStyles(styles), withFirebase)(AdminPage);
