import React, {Component} from 'react';

import PropTypes from 'prop-types';

import withWidth from '@material-ui/core/withWidth';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {AuthUserContext} from "../Session";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import BarButton from "./barButton";

import IconButton from '@material-ui/core/IconButton';
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import StarIcon from "@material-ui/icons/Star";
import StarsIcon from "@material-ui/icons/Stars";

const styles = (theme) => ({
    left: {
        flexGrow: 1,
    },

    right: {
        flexGrow: 1,
    },

    signUpButton: {
        marginRight: theme.spacing(1)
    },
    iconButton: {
        color: theme.palette.primary.contrastText,
    }
});

class Bar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
        };
    }

    render() {

        // Styling
        const {classes, width} = this.props;
        // Properties
        const {title} = this.props;
        const goToPage = (page) => () => {
            toggleDrawer();
            this.props.history.push(page);
        };

        const { showDrawer } = this.state;
        const toggleDrawer = () => {
            if (!['xs', 'sm'].includes(width)) { return; }
            this.setState({ showDrawer: !this.state.showDrawer });
        };

        return (
            <AuthUserContext.Consumer>
                {authUser => {
                    return (
                        <AppBar color="primary" position="static">
                            <Toolbar variant="regular">
                                <div className={classes.left}>
                                    <Hidden smDown>
                                        <BarButton onClick={goToPage(ROUTES.LANDING)}>Forside</BarButton>
                                        <BarButton onClick={goToPage(ROUTES.CAMPS)}>Lejre</BarButton>
                                        <BarButton onClick={goToPage(ROUTES.RECORDS)}>Rekorder</BarButton>
                                        {authUser && !!authUser.roles[ROLES.ADMIN] &&
                                        <BarButton onClick={goToPage(ROUTES.ADMIN)}>Admin</BarButton>}
                                    </Hidden>
                                    <Hidden mdUp>
                                        <IconButton className={classes.iconButton} onClick={toggleDrawer}>
                                            <MenuIcon/>
                                        </IconButton>
                                        <Drawer anchor="top" open={showDrawer} onClose={toggleDrawer}>
                                            <List>
                                                <ListItem button onClick={goToPage(ROUTES.LANDING)}>
                                                    <ListItemIcon>
                                                        <HomeIcon color="primary"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Forside" />
                                                </ListItem>
                                                <ListItem button onClick={goToPage(ROUTES.CAMPS)}>
                                                    <ListItemIcon>
                                                        <ArtTrackIcon color="primary"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Lejre" />
                                                </ListItem>
                                                <ListItem button onClick={goToPage(ROUTES.RECORDS)}>
                                                    <ListItemIcon>
                                                        <StarIcon color="primary"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Rekorder"/>
                                                </ListItem>
                                                {authUser && !!authUser.roles[ROLES.ADMIN] &&
                                                (
                                                    <React.Fragment>
                                                        <Divider />
                                                        <ListItem button onClick={goToPage(ROUTES.ADMIN)}>
                                                            <ListItemIcon>
                                                                <StarsIcon color="secondary"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary="Admin"/>
                                                        </ListItem>
                                                    </React.Fragment>
                                                )}
                                            </List>
                                        </Drawer>
                                    </Hidden>
                                </div>
                                <Hidden xsDown>
                                    <Typography style={{flexGrow: 1}} color="inherit" variant="h6">{title}</Typography>
                                </Hidden>
                                {authUser && (
                                    <Hidden mdDown>
                                        <Typography variant="overline" display="block">
                                            {authUser.username} -
                                        </Typography>
                                    </Hidden>
                                )}
                                {authUser && <SignOutButton/>}
                                {!authUser &&
                                <BarButton onClick={() => this.props.history.push(ROUTES.SIGN_IN)}>Log ind</BarButton>
                                }
                            </Toolbar>
                        </AppBar>
                    )
                }
                }
            </AuthUserContext.Consumer>
        );
    }
}

Bar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
};

export default compose(withWidth(), withStyles(styles), withRouter)(Bar);