import React, {Component} from 'react';
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withStyles} from "@material-ui/core/styles"
import {withRouter} from 'react-router-dom';

import AuthUserContext from "../../Session/context";
import {Container} from "@material-ui/core";
import * as ROUTES from "../../../constants/routes";
import CircularProgress from "@material-ui/core/CircularProgress";

import Camp from './camp';

const styles = (theme) => ({
    progress: {
        width: 50,
        margin: '1em auto',
    }
});

class CampPage extends Component {

    constructor(props) {
        super(props);
        const leave = () => { this.props.history.push(ROUTES.CAMPS) };
        if (this.props.match && this.props.match.params) {
            const {camp: campId} = this.props.match.params;
            this.campId = campId;
            if (this.campId === undefined) { leave(); }
        } else {
           leave();
        }
        this.state = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.camp(this.campId).on('value', snapshot => {
                const campObj = snapshot.val();
                this.setState({...campObj, loading: false});
            }
        )
    }

    componentWillUnmount() {
        this.props.firebase.camp(this.campId).off();
    }

    render() {
        const { classes } = this.props;
        const { loading, year, week, content, albums, videos, text } = this.state;
        return (
            <AuthUserContext.Consumer>
                {authUser => {
                    return loading ? (<div className={classes.progress}><CircularProgress/></div>) :
                    (year && authUser && !!authUser.roles[this.campId]) &&
                        (
                            <Container maxWidth="md">
                                <Camp camp={{year, week, content, albums, videos, text}} />
                            </Container>
                        )
                }}
            </AuthUserContext.Consumer>
        );
    }
}

export default compose(withRouter, withFirebase, withStyles(styles))(CampPage);
