import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {Container} from "@material-ui/core";
import {withStyles, makeStyles, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography'

import * as ROLES from '../../constants/roles';


const SignUpPage = () => {
	const classes = useStyles();
	return (
		<Container maxWidth='md' className={classes.root}>
			<Typography variant="h4">
				Registrering
			</Typography>
			<SignUpForm/>
		</Container>
	);
};

const styles = theme => ({
	textField: {
		display: 'block',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		maxWidth: '95%',
	},
	root: {
		marginTop: theme.spacing(4),
	},
});
const useStyles = makeStyles(theme => createStyles(styles(theme)));

const INITIAL_STATE = {
	username: '',
	email: '',
	passwordOne: '',
	passwordTwo: '',
	error: null,
	showPassword: false,
};

class SignUpFormBase extends Component {
	constructor(props) {
		super(props);
		this.state = {...INITIAL_STATE};
	}

	onSubmit = event => {
		const {username, email, passwordOne, isAdmin} = this.state;
		const roles = {};
		if (isAdmin) { roles[ROLES.ADMIN] = ROLES.ADMIN }
		this.props.firebase
			.doCreateUserWithEmailAndPassword(email, passwordOne)
			.then(authUser => {
				return this.props.firebase.user(authUser.user.uid)
					.set({
						username, email, roles
					});
			})
			.then(() => {
				this.setState({...INITIAL_STATE});
				this.props.history.push(ROUTES.HOME);
			})
			.catch(error => {
				this.setState({error});
			});
		event.preventDefault();
	};
	onChange = event => {
		this.setState({[event.target.name]: event.target.value});
	};
	handleClickShowPassword = () => {
		this.setState({showPassword: !this.state.showPassword})
	};

	render() {
		const {classes} = this.props;
		const {showPassword} = this.state;
		const {username, email, passwordOne, passwordTwo, error,} = this.state;
		const isInvalid =
			passwordOne !== passwordTwo ||
			passwordOne === '' ||
			email === '' ||
			username === '';

		return (
			<form onSubmit={this.onSubmit}>
				<Grid container spacing={4}>
					<Grid container item sm={12} md={6} lg={3}>
						<TextField
							fullWidth
							type="text"
							name="username"
							label="Fulde Navn"
							className={classes.textField}
							value={username}
							onChange={this.onChange}/>
					</Grid>
					<Grid container item sm={12} md={6} lg={3}>
						<TextField
							fullWidth
							type="email"
							name="email"
							label="Email"
							className={classes.textField}
							value={email}
							onChange={this.onChange}/>
					</Grid>
					<Grid container item sm={12} md={6} lg={3}>
						<TextField
							fullWidth
							type={showPassword ? 'text' : 'password'}
							name="passwordOne"
							label="Adgangskode"
							className={classes.textField}
							value={passwordOne}
							onChange={this.onChange}
						/>
					</Grid>
					<Grid container item sm={12} md={6} lg={3}>
						<TextField
							fullWidth
							type={showPassword ? 'text' : 'password'}
							name="passwordTwo"
							label="Bekræft kode"
							className={classes.textField}
							value={passwordTwo}
							onChange={this.onChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="Toggle password visibility"
											onClick={this.handleClickShowPassword}
										>
											{showPassword ? <VisibilityOff/> : <Visibility/>}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item sm={12}>
					</Grid>
				</Grid>
				<Grid container item sm={12}>
					<Button disabled={isInvalid} variant="contained" type="submit">Sign Up</Button>
					{error && <p>{error.message}</p>}
				</Grid>
			</form>
		)
			;
	}
}

const SignUpLink = () => (
	<p>
		Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
	</p>
);

const SignUpForm = compose(
	withStyles(styles),
	withRouter,
	withFirebase,
)(SignUpFormBase);

export default SignUpPage;
export {SignUpForm, SignUpLink}
