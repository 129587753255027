import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const pfpStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
    }
}));

const PasswordForgetPage = () => {
    const classes = pfpStyles();
    return (
        <Container maxWidth='sm' className={classes.root}>
            <Typography variant="h4">
                Glemt Password
            </Typography>
            <PasswordForgetForm />
        </Container>
    );
}

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;

        // eslint-disable-next-line no-useless-escape
        const isInvalid2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        const isInvalid = email === '' || !isInvalid2;

        return (
            <form onSubmit={this.onSubmit}>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField
                            name="email"
                            label="Email"
                            fullWidth
                            value={this.state.email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Email"
                        />
                    </Grid>
                    <Grid item>
                        <Button disabled={isInvalid} type="submit" variant='contained' color='primary'>
                            Reset password
                        </Button>
                        {error && <p>{error.message}</p>}
                    </Grid>
                </Grid>
            </form>
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };