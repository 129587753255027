import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
}));

const SignInPage = () => {
    const classes = useStyle();
    return (
        <Container maxWidth='sm' className={classes.root}>
            <Typography variant="h4">
                Log ind
            </Typography>
            <SignInForm/>
            {/*<PasswordForgetLink />*/}
            {/*<SignUpLink/>*/}
        </Container>
    );
};

const formBaseStyles = theme => ({
    root: {
        marginTop: theme.spacing(2),
    }
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    onSubmit = event => {
        const {email, password} = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({...INITIAL_STATE});
                this.props.history.push(ROUTES.LANDING);
            })
            .catch(error => {
                this.setState({error});
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const { classes } = this.props;
        const {email, password, error} = this.state;
        const isInvalid = password === '' || email === '';
        return (
            <form onSubmit={this.onSubmit} className={classes.root}>
                <Grid container spacing={3} >
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Email Address"/>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            name="password"
                            label="Kodeord"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Password"/>
                    </Grid>
                    <Grid item sm={12}>
                        <Button disabled={isInvalid} color="primary" margin="normal" fullWidth variant='contained' type="submit">
                            Log Ind
                        </Button>
                        {error && <p>{error.message}</p>}
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const SignInForm = compose(
    withStyles(formBaseStyles),
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignInPage;

export {SignInForm};
