import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles'
import {withFirebase} from "../../Firebase";
import {compose} from 'recompose';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    card: {
        minWidth: 275,
    },
});

class SplitCampBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            camps: [],
            camps_public: [],
            camps_private: [],
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.firebase.camps().on('value', snapshot => {
            const campsObj = snapshot.val();
            const campsList = Object.keys(campsObj).map(key => ({
                ...campsObj[key],
                uid: key,
            }));


            const camps_public = Object.keys(campsObj).map(key =>
                (({year, week, content}) => ({year, week, content}))(campsObj[key])
            );
            console.log(camps_public);

            const camps_private = Object.keys(campsObj).map(key =>
                (({text, albums, videos}) => ({text, albums, videos}))(campsObj[key])
            );
            console.log(camps_private);


            this.setState({
                camps: campsList,
                camps_public: camps_public,
                camps_private: camps_private,
                loading: false,
            })
        });
    }

    componentWillUnmount() {
        this.props.firebase.records().off();
    }

    render() {
        const {camps_public, camps_private, loading} = this.state;
        return (
            <div>
                <h2>Public</h2>
                {loading && (<div>
                    <Typography variant="overline" display="block">
                        Loading...
                    </Typography>
                </div>)}

                <ul>
                {!loading && camps_public && camps_public.map((camp, idx) => (
                            <li key={camp.uid}>
                                <b>public</b> <React.Fragment>{Object.keys(camp).map((k) => (<p>{k}, {camp[k]}</p>))}</React.Fragment><br/>
                                <b>private</b> {camps_private[idx] !== undefined &&  camps_private[idx].text != undefined && camps_private[idx].text}
                            </li>
                    ))}
                </ul>
                )}
            </div>
        );
    }
}

export default compose(withFirebase, withStyles(styles))(SplitCampBtn);
