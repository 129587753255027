import {withStyles} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {green, purple} from '@material-ui/core/colors';

const ColorFab = color => withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(color[800]),
        backgroundColor: color[500],
        '&:hover': {
            backgroundColor: color[700],
        },
    },
}))(Fab);

const GreenFab = ColorFab(green);
const PurpleFab = ColorFab(purple);

export {GreenFab, PurpleFab}
export default ColorFab;
