import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
    BarButton: {
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
    },
});


class BarButton extends Component {
    render() {
        const { classes } = this.props;
        const { onClick } = this.props;
        return (<Button color="secondary" variant="text" className={classes.BarButton} onClick={onClick}>{this.props.children}</Button>);
    }
}

BarButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(BarButton);
