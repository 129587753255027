import React from 'react';
import {Container, createStyles, makeStyles, Typography, Paper} from '@material-ui/core';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        minHeight: 100,
    },
});
const useStyles = makeStyles(theme => createStyles(styles(theme)));

const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Paper className={classes.paper}>
                <Typography variant="h1">Siden blev ikke fundet</Typography>
            </Paper>
        </Container>
    );
};
export default NotFoundPage;