import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LandingPage from '../Landing';
import SignInPage from '../SignIn';
import AdminPage from '../Admin';
import CampsPage from "../Camps";
import CampPage from "../Camps/Details";
import NotFoundPage from "../NotFound";
import PasswordForgetPage from "../PasswordForget";
import SignUpPage from "../SignUp";
import {RecordPage, RecordsPage} from "../Records";
import NewRecordPage from "../Records/newRecord";

import * as ROUTES from '../../constants/routes';
import {withAuthentication} from "../Session";
import withRoot from "../../theme/withRoot";

import Bar from "../Navigation/bar";
import Footer from "../Footer";
import {createStyles, makeStyles} from "@material-ui/core";

import ReactGA from 'react-ga';
ReactGA.initialize('G-L35WLWDXMY');
ReactGA.pageview(window.location.pathname + window.location.search);

const styles = theme => ({
    root: {
        minHeight: '100%',
        height: 'auto',
        position: 'relative',
    },
    routes: {
        minHeight: '70vh',
        height: 'auto',
    },
    footer: {
        marginTop: theme.spacing(4),
        width: '100%',
    }
});
const useStyles = makeStyles(theme => createStyles(styles(theme)));

const App = () => {
    const classes = useStyles();
    return (
        <Router>
            <div className={classes.root}>
                <Bar title="Gymnastiklejr"/>

                <div className={classes.routes}>
                    <Switch>
                        <Route exact path={ROUTES.LANDING} component={LandingPage}/>
                        <Route exact path={ROUTES.CAMPS} component={CampsPage}/>
                        <Route path={ROUTES.CAMPS + '/:camp'} component={CampPage}/>
                        <Route exact path={ROUTES.RECORDS} component={RecordsPage}/>
                        <Route path={ROUTES.NEW_RECORD} component={NewRecordPage}/>
                        <Route path={ROUTES.RECORDS + '/:record'} component={RecordPage}/>
                        <Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
                        <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
                        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
                        {/*<Route path={ROUTES.HOME} component={HomePage}/>*/}
                        {/*<Route path={ROUTES.ACCOUNT} component={AccountPage}/>*/}
                        <Route path={ROUTES.ADMIN} component={AdminPage}/>

                        <Route exact path="*" component={NotFoundPage}/>
                    </Switch>
                </div>

                <div className={classes.footer}>
                    <Footer/>
                </div>
            </div>
        </Router>
    );
}

export default withRoot(withAuthentication(App));
