import React from 'react';
import {withFirebase} from "../Firebase";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button"
// import {compose} from 'recompose'
import PropTypes from 'prop-types';

const styles = theme => ({
    signOutBtn: {
        color: theme.palette.common.white,
    },
});

function SignOutButton(props) {
    const {firebase, classes} = props;
    return (
        <Button
            variant="text"
            underline="none"
            type='button'
            color="inherit"
            className={classes.signOutBtn}
            onClick={firebase.doSignOut}>
            Log Ud
        </Button>
    );
}

SignOutButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withFirebase(SignOutButton));
// export default compose(withStyles(styles), withFirebase)(SignOutButton); // withStyles(styles)(withFirebase(SignOutButton));
