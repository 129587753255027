import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withFirebase} from '../../Firebase';
import {compose} from 'recompose';

import Remarkable from 'remarkable';

import ImageUploader from '../../ImageUploader';
import DisplayUrls from '../../DisplayUrls';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import {CircularProgress} from "@material-ui/core";

const styles = theme => ({
	root: {
		padding: theme.spacing(2),
	},
	textField: {
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 80,
	},
	fullWidth: {
		width: '100%',
	},
	textArea: {
		width: '100%',
	},
	markdownContent: {
		width: '80%',
		margin: '1em auto',
		padding: theme.spacing(2),
		border: 'solid 1px #ececec',
		backgroundColor: theme.palette.background.list,
	}
});

const INITIALIZE_CAMP = {
	year: new Date().getFullYear(),
	week: 27,
	text: '',
	albums: [],
	videos: [],
	content: [],
	loading: true,
	saving: false,
	error: null,
};


class CampEdit extends Component {
	constructor(props){
		super(props);

		// Construct new
		this.state = {...INITIALIZE_CAMP};
	}

    componentDidMount() {
		this.setState({loading: true});
		this.props.firebase.camp(this.props.uid).on('value', snapshot => {
				const campObj = snapshot.val();
				this.setState({...campObj, loading: false});
			}
		)
    }

    componentWillUnmount() {
        this.props.firebase.records(this.props.uid).off();
    }

	render() {
		const { classes } = this.props;
		const { year, week, albums, videos, content, loading, saving, text } = this.state;
		
		// fns
		const onListChange = listName => list => {
			this.setState({ [listName]: list })
		};
		const onFieldChange = event => {
			this.setState({ [event.target.name]: event.target.value, });
		};

		const getRawMarkup = () => {
			const md = new Remarkable();
			return { __html: md.render(this.state.text) };
		};

		const onSave = () => {
			this.setState({saving: true});
			const { year, week, albums, videos, content, text } = this.state;
			this.props.firebase.camp(this.props.uid).set({
                    year, week, albums, videos, content, text
                })
				.then(() => { this.setState({saving: false}) });
		};

		return (
			<Grid container spacing={3} className={classes.root}>
				{ loading ? 
					<p>Loading...</p> : 
					<React.Fragment>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={8}>
								<TextField
									fullWidth
									name="year"
									label="År"
									value={year}
									onChange={onFieldChange}
									type="number"
									className={classes.textField}
									margin="normal" />
							</Grid>
							<Grid item xs={2}>
								<FormControl className={classes.formControl}>
									<InputLabel htmlFor="week-simple">Uge</InputLabel>
									<Select name="week" value={week}
											onChange={onFieldChange}
											variant="filled">
										<MenuItem value={26}>26</MenuItem>
										<MenuItem value={27}>27</MenuItem>
										<MenuItem value={28}>28</MenuItem>
										<MenuItem value={29}>29</MenuItem>
										<MenuItem value={30}>30</MenuItem>
										<MenuItem value={31}>31</MenuItem>
										<MenuItem value={32}>32</MenuItem>
									</Select>
								</FormControl>
							</Grid>
                            <Grid item xs={2}>
								<Button color="primary" onClick={onSave}>
									Gem
								</Button>
								{ saving && <CircularProgress /> }
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item className={classes.fullWidth}>
							<ImageUploader urls={content} onListChange={onListChange('content')}  /> 
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1" component="h5">
								Privat tekst
							</Typography>
							<Typography variant="subtitle2">
							</Typography>
							<label htmlFor="markdown-content">
								Tekst til forældrene. <small>Brug evt. <Link href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">markdown</Link> til at formatere din tekst.</small>
							</label>
							<TextareaAutosize
								id="markdown-content"
								name="text"
								onChange={onFieldChange}
								value={text}
								className={classes.textArea}
								rows={3}
							/>
							<Typography variant="subtitle2" component="h5">
								Det bliver vist:
							</Typography>
							<div
								className={classes.markdownContent}
								dangerouslySetInnerHTML={getRawMarkup()}
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1" component="h5">
								Private billeder
							</Typography>
							<DisplayUrls variant="photos" urls={albums} onListChanged={onListChange('albums')}/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1" component="h5">
								Private Youtube videoer
							</Typography>
							<DisplayUrls variant="youtube" urls={videos} onListChanged={onListChange('videos')}/>
						</Grid>
					</React.Fragment>
				}
			</Grid>
		);
	}
}

export default compose(withFirebase, withStyles(styles))(CampEdit);
