import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';

import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import RecordList from './recordList';
import {AuthUserContext} from '../Session';

import {GreenFab} from "../Fabs";
import RecordPage from './recordPage'

const styles = theme => ({
    root: {
        position: 'relative',
    },
    fab: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(3)
    },
});

class RecordsPageBase extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Container className={classes.root} maxWidth="md">
                <h1>Rekorder</h1>
                <AuthUserContext.Consumer>
                    {authUser => authUser && !!authUser.roles[ROLES.ADMIN] &&
                        (<GreenFab href={ROUTES.NEW_RECORD} color="secondary" size="small" className={classes.fab}
                              aria-label="Add">
                            <AddIcon/>
                        </GreenFab>)}
                </AuthUserContext.Consumer>
                <hr/>
                <RecordList/>
            </Container>
        );
    }
}
const RecordsPage = compose(withStyles(styles))(RecordsPageBase);

export {RecordPage, RecordsPage};
