import React, {Component} from 'react';
import {withAuthorization} from "../Session";
import {withFirebase} from "../Firebase";
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';

import * as ROUTES from '../../constants/routes';
import * as OBJECTS from '../../constants/objects';
import HolderList from "./holderList";
import {withRouter} from "react-router-dom";

import {GreenFab, PurpleFab} from "../Fabs";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    relative: {
        position: 'relative',
    },
    btnBottomRight: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1)
    },
    container: {
        flexWrap: 'wrap',
    },
    textField: {
        display: 'block',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
});

const isFirstInvalid = holders => !holders || holders.length === 0
    || holders[0].names.length === 0
    || holders[0].amount.length === 0;

const getNewHolder = () => {
    const day = new Date();
    const date = day.toLocaleDateString();
    return {
        names: '',
        date: date,
        amount: '',
    };
};

class RecordPageBase extends Component {
    recordId = '';
    hasId = false;

    constructor(props) {
        super(props);
        if (this.props.match && this.props.match.params) {
            const {record: recordId} = this.props.match.params;
            this.recordId = recordId;
            this.hasId = recordId !== undefined;
        }

        this.state = {...OBJECTS.INITIAL_RECORD_STATE, loading: false,};
        if (!this.hasId) {
            this.state.holders.push(getNewHolder());
        }
    }

    componentDidMount() {
        if (this.hasId) {
            this.setState({loading: true});
            this.props.firebase.record(this.recordId).on('value', snapshot => {
                    const recordObj = snapshot.val();
                    this.setState({...recordObj, loading: false});
                }
            )
        }
    }

    componentWillUnmount() {
        this.props.firebase.records(this.recordId).off();
    }

    onRecordChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    onHolderChange = idx => event => {
        const state = {...this.state};
        state.holders[idx][event.target.name] = event.target.value;
        this.setState({state});
    };

    onHolderDelete = idx => () => {
        const { holders } = this.state;
        holders.splice(idx, 1);
        this.setState([holders])
    };

    addEmptyRecord = () => {
        const holder = getNewHolder();
        const {holders} = this.state;
        holders.unshift(holder);
        this.setState({holders})
    };

    onSubmit = event => {
        const {name, note, holders} = this.state;
        if (isFirstInvalid(holders)) {
            holders.shift();
        }

        let ref;
        if (!this.hasId) {
            ref = this.props.firebase.records().push();
            this.recordId = ref.key;
        } else {
            ref = this.props.firebase.record(this.recordId);
        }

        ref.set({name, note, holders})
            .then(() => {
                this.props.history.push(ROUTES.RECORDS);
            })
            .catch((error) => {
                this.setState({error});
            });
        event.preventDefault();
    };

    render() {
        const {name, note, holders, error} = this.state;
        const {classes} = this.props;

        const holderInvalid = isFirstInvalid(holders);

        return (
            <Container className={clsx(classes.relative, classes.root)} >
                <form className={classes.container} noValidate onSubmit={this.onSubmit}>
                    <Grid container spacing={4}>
                        <Grid m={0} item xs={12}>
                            <Typography variant="h4" className={classes.title}>
                                Rekorden
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                id="name"
                                name="name"
                                label="Rekordnavn"
                                value={name}
                                fullWidth
                                onChange={this.onRecordChange}
                                margin="normal"/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                id="note"
                                name="note"
                                label="Beskrivelse"
                                value={note}
                                fullWidth
                                onChange={this.onRecordChange}
                                margin="normal"/>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.relative}>
                            <Typography m={3} variant="h6" className={classes.title}>
                                Nyeste Rekordholder
                            </Typography>
                            {
                                holders && holders.length > 0 &&
                                (
                                    <React.Fragment>
                                        <span>{holders[0].date}</span>
                                        <React.Fragment>
                                            <TextField
                                                className={classes.textField}
                                                name="names"
                                                label="Navne"
                                                value={holders[0].names}
                                                onChange={this.onHolderChange(0)}
                                                margin="normal"/>
                                            <TextField
                                                className={classes.textField}
                                                name="amount"
                                                label="Mængde"
                                                value={holders[0].amount}
                                                onChange={this.onHolderChange(0)}
                                                margin="normal"/>
                                        </React.Fragment>
                                    </React.Fragment>
                                )
                            }
                            <PurpleFab onClick={this.addEmptyRecord} disabled={holderInvalid} size="small"
                                       className={clsx(classes.btnBottomRight)}
                                       aria-label="Add">
                                <AddIcon/>
                            </PurpleFab>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.relative}>
                            <Typography variant="h6" className={classes.title}>
                                Gamle rekorder
                            </Typography>
                            <div>
                                <HolderList holders={holders} onHolderDeleted={this.onHolderDelete} editable={true}/>
                            </div>
                            <Box m={3}>
                                <GreenFab type="submit" size="small"
                                          className={classes.btnBottomRight}
                                          arial-label="Save">
                                    <SaveIcon/>
                                </GreenFab>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {error && <p>{error.message}</p>}
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

const condition = authUser => !!authUser;
const RecordPage = compose(withAuthorization(condition), withStyles(styles), withFirebase, withRouter)(RecordPageBase);

export default RecordPage;
