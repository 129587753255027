import React, {Component} from 'react';
import {compose} from 'recompose';

import {Link} from 'react-router-dom';

import {withFirebase} from "../../Firebase";
import {withAuthorization} from '../../Session';
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";

import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


const styles = theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3,2),
    },
});

class RecordsButton extends Component {
	componentDidMount() {
		this.setState({loading: false});
	}

	componentWillUnmount() {
		this.props.firebase.records().off();
	}

    render() {
		const onGenerate = () => {
			this.setState({loading: true});
			this.props.firebase.records().on('value', snapshot => {
				const recordsObj = snapshot.val();
				const recordsList = Object.keys(recordsObj).map(key => (
					[key, recordsObj[key].name, recordsObj[key].holders == undefined ? "" : recordsObj[key].holders[0].amount]
				));

				const {vfs} = pdfFonts.pdfMake;
				pdfMake.vfs = vfs;
				
				var docDefinition = {
					content: [
						{ text: 'Rekorder', style: 'header' },
						'date',
						{
							style: 'tableExample',
							table: {
								headerRows: 1,
								widths: [50, '*', 100],
								body: [
									[{ text: '#', style: 'tableHeader' }, { text: 'Navn', style: 'tableHeader' }, { text: 'Rekord', style: 'tableHeader' }],
									...recordsList
								],
							},
							layout: 'lightHorizontalLines'
						},
					],

					styles: {
						header: {
							fontSize: 18,
							bold: true,
							margin: [0, 0, 0, 10]
						},
						tableHeader: {
							bold: true,
							fontSize: 12,
							color: 'black'
						},
						tableExample: {
							margin: [0, 5, 0, 15]
						},
					},
				
					defaultStyle: {
						fontSize: 10
					}
				};
				pdfMake.createPdf(docDefinition).open();

				this.setState({
					loading: false,
				})
			});
		}
        
        const { classes } = this.props;
        const { loading } = this;

        return (
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Typography variant="h5" component="h3">
						Generer Rekord Liste
					</Typography>
					<Button color="primary" onClick={onGenerate}>
							Generer!
					</Button>
				</Paper>
			</Grid>
        );
    }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
export default compose(withAuthorization(condition), withStyles(styles), withFirebase)(RecordsButton);
