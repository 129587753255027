import React from 'react';
import {createStyles, makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Typography,
    Link,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";

import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 240,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        overflow: 'hidden'
    },
    grid1: {
        marginBottom: 0,
    },
    lightText: {
        color: theme.palette.primary.contrastText,
    },
    grey: {
        color: theme.palette.primary.dark,
    },
    content: {
        padding: theme.spacing(4),
    },
    contactGrid: {
        borderBottom: 'solid 1px #eee',
    },
    info: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    icon: {
        color: theme.palette.primary.contrastText,
    },
    gu: {
        maxHeight: 35,
    },
});
const useStyles = makeStyles(theme => createStyles(styles(theme)));

const Footer = () => {
    const classes = useStyles();
    return (
        <Container component="footer" className={classes.root}>
            <Grid container spacing={8} className={classes.grid1}>
                <Hidden only={'md'}>
                    <Grid item sm={12} lg={4} className={classes.content}>
                    </Grid>
                </Hidden>
                <Grid container item sm={12} md={6} lg={4} className={classes.content} spacing={3}>
                    <Grid item xs={12} className={classes.contactGrid}>
                        <Typography variant="h6" className={classes.lightText}>
                            Kontakt
                        </Typography>
                    </Grid>
                    <Divider component="hr" light/>
                    <Grid item xs={12}>
                        <List className={classes.list}>
                            <ListItem>
                                <ListItemIcon><MailIcon className={classes.icon}/></ListItemIcon>
                                <ListItemText>
                                    <Link className={classes.lightText}
                                          href="mailto:email@gymnastiklejr.dk">email@gymnastiklejr.dk</Link>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><ContactSupportIcon className={classes.icon}/></ListItemIcon>
                                <ListItemText>
                                    <Link className={classes.lightText}
                                          href="https://www.dgi.dk/foreningsledelse/min-landsdelsforening/dgi-oestjylland">
                                        DGI Kontoret
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><HomeIcon className={classes.icon}/></ListItemIcon>
                                <ListItemText>
                                    <Link className={classes.lightText}
                                          href="http://www.gudenaadalen.dk"
                                          target="_blank"
                                    >
                                        <img src={process.env.PUBLIC_URL + 'images/gu.png'} className={classes.gu} alt="logo"/>
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><ContactPhoneIcon className={classes.icon}/></ListItemIcon>
                                <ListItemText primary="Lejrleder" secondary="Se info under den enkelte lejr."/>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={6} lg={4} className={classes.content}>
                    <Typography variant="body2" className={classes.grey} align="right">
                        @Copyright <Link href="https://fhvilshoj.github.io" target="_blank" className={classes.grey}>Frederik
                        Hvilshøj</Link> 2021
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withStyles(styles)(Footer)