import React, {Component} from 'react';
import {compose} from 'recompose';
import {withFirebase} from '../../Firebase';
import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import PersonIcon from '@material-ui/icons/Person';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';

import * as ROLES from '../../../constants/roles';
import {CircularProgress} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    loading: {
        width: '50px',
        margin: '2em auto',
    },
    tagContainer: {
        textAlign: 'center',
    },
    collapse: {
        backgroundColor: theme.palette.background.list,
    },
    collapseContainer: {
        margin: theme.spacing(2),
    },
});

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsers: false,
            loadingCamps: false,
            users: [],
            camps: [],
            error: null,
            expandUser: '',
        }
    }

    componentDidMount() {
        this.setState({loadingUsers: true});
        this.props.firebase.users().on('value', snapshot => {
            const usersObject = snapshot.val();
            const userList = Object.keys(usersObject).map(key => ({
                ...usersObject[key],
                uid: key,
            }));
            this.setState({
                users: userList,
                loadingUsers: false,
            })
        });
        this.setState({loadingCamps: true});
        this.props.firebase.camps().on('value', snapshot => {
            const campsObj = snapshot.val();
            const campsList = Object.keys(campsObj).map(key => ({
                ...campsObj[key],
                uid: key,
            }));
            this.setState({
                camps: campsList,
                loadingCamps: false,
            })
        });
    }

    onUserPriveledgesChecked = (idx, role) => event => {
        const {uid, roles} = this.state.users[idx];
        const newRoles = roles ? roles : {};
        if (role in newRoles) {
            delete newRoles[role];
        } else {
            newRoles[role] = role;
        }

        this.props.firebase.user(uid)
            .update({roles: newRoles})
            .catch(error => {
                this.setState({error})
            });
    };

    componentWillUnmount() {
        this.props.firebase.users().off();
        this.props.firebase.camps().off();
    }

    render() {
        const {classes} = this.props;
        const {users, camps, loadingUsers, loadingCamps, error, expandUser} = this.state;

        const toggleCollapse = (uid) => () => {
            const {expandUser} = this.state;
            this.setState({expandUser: uid === expandUser ? '' : uid});
        };

        if (loadingUsers || loadingCamps) {
            return (
                <div className={classes.loading}>
                    <CircularProgress/>
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <List component="ul">
                        {users.map((user, idx) => {
                            const {roles} = user;
                            const admin = roles !== undefined && roles[ROLES.ADMIN] !== undefined;
                            const hideAdmin = user.roles !== undefined && ROLES.ADMIN in user.roles;

                            return (
                                <React.Fragment key={user.uid}>
                                    <ListItem button onClick={toggleCollapse(user.uid)}>
                                        <ListItemIcon>
                                            {admin ? <StarBorderIcon/> : <PersonIcon/>}
                                        </ListItemIcon>
                                        <ListItemText primary={user.username} secondary={user.email}/>
                                        <ListItemSecondaryAction onClick={toggleCollapse(user.uid)}>
                                            {expandUser === user.uid ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Collapse in={expandUser === user.uid} className={classes.collapse}>
                                        <Grid container spacing={3} className={classes.collapseContainer}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">Tilladelser:</Typography>
                                                <div className={classes.tagContainer}>
                                                    {roles && Object.keys(roles).map(key => {
                                                        const role = user.roles[key];
                                                        let description = "";
                                                        if (key === ROLES.ADMIN) {
                                                            description = "Admin"
                                                        } else {
                                                            const camp = camps.find(c => c.uid === role);
                                                            if (camp == null) {
                                                                return null;
                                                            }
                                                            description = `${camp.year}-${camp.week}`;
                                                        }
                                                        return (
                                                            <Chip key={key} label={description} size="small"
                                                                  onDelete={this.onUserPriveledgesChecked(idx, key)}
                                                                  variant="outlined" color="secondary"
                                                                  component="span"
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">Giv tilladelse:</Typography>
                                                <div className={classes.tagContainer}>
                                                    {camps && camps.map(camp => {
                                                        if (user.roles == null || !(camp.uid in user.roles)) {
                                                            return (
                                                                <Chip key={camp.uid} variant="outlined"
                                                                      color="primary"
                                                                      label={`${camp.year}-${camp.week}`}
                                                                      size="small"
                                                                      onDelete={this.onUserPriveledgesChecked(idx, camp.uid)}
                                                                      component="span"
                                                                      deleteIcon={<DoneIcon/>}
                                                                />
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                    {
                                                        !hideAdmin &&
                                                        <Chip variant="outlined"
                                                              color="primary"
                                                              label="Admin"
                                                              size="small"
                                                              onDelete={this.onUserPriveledgesChecked(idx, ROLES.ADMIN)}
                                                              component="span"
                                                              deleteIcon={<DoneIcon/>}
                                                        />
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                    </List>
                    {
                        error && <p>{error.message}</p>
                    }
                </React.Fragment>);
        }
    }
}

export default compose(withFirebase, withStyles(styles))(UserList);
