import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const devConfig = {
    apiKey: "AIzaSyA8VxqjjFURzcyhiehMM4I6Uf9X-QlvNLE",
    authDomain: "gymlejr-b0d7b.firebaseapp.com",
    databaseURL: "https://gymlejr-b0d7b.firebaseio.com",
    projectId: "gymlejr-b0d7b",
    storageBucket: "gymlejr-b0d7b.appspot.com",
    messagingSenderId: "403991144347",
    appId: "1:403991144347:web:68122b8af50e134b"
};

// Dev, TODO: create new for production
const prodConfig = {
    apiKey: "AIzaSyA8VxqjjFURzcyhiehMM4I6Uf9X-QlvNLE",
    authDomain: "gymlejr-b0d7b.firebaseapp.com",
    databaseURL: "https://gymlejr-b0d7b.firebaseio.com",
    projectId: "gymlejr-b0d7b",
    storageBucket: "gymlejr-b0d7b.appspot.com",
    messagingSenderId: "403991144347",
    appId: "1:403991144347:web:68122b8af50e134b"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth   = app.auth();
        this.db     = app.database();
        this.st     = app.storage();
    }

    // *** Merge Auth and DB User API *** //
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if(authUser) {
                this.user(authUser.uid)
                    .once('value')
                    .then(snapshot => {
                        const dbUser = snapshot.val();
                        if(!dbUser.roles) {
                            dbUser.roles = {};
                        }

                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            ...dbUser,
                        };

                        next(authUser);
                    })
            } else {
                fallback();
            }
        });

    // // // //  Authentication
    doCreateUserWithEmailAndPassword    = (email, password) =>  this.auth.createUserWithEmailAndPassword(email, password);
    doSignInWithEmailAndPassword        = (email, password) =>  this.auth.signInWithEmailAndPassword(email, password);
    doSignOut                           = () =>                 this.auth.signOut();
    doPasswordReset                     = email =>              this.auth.sendPasswordResetEmail(email);
    doPasswordUpdate                    = password =>           this.auth.currentUser.updatePassword(password);

    // // // //  Database
    // Users
    user                                = uid => this.db.ref(`users/${uid}`);
    users                               = ()  => this.db.ref('users');

    // Records
    record                              = uid => this.db.ref(`records/${uid}`);
    records                             = ()  => this.db.ref('records');
    recordHolders                       = uid => this.db.ref(`records/${uid}/holders`);

    // Camps
    camp                                = uid => this.db.ref(`camps/${uid}`);
    camps                               = () => this.db.ref('camps');

    camp_public                         = uid => this.db.ref(`camps_public/${uid}`);
    camps_public


    // ***  STORAGE  *** //
    public_image                        = uid => this.st.ref(`images/public/${uid}`);
    public_images                       = () => this.st.ref('images/public');
    restricted_image                    = uid => this.st.ref(`images/restricted/${uid}`);
    restricted_images                   = () => this.st.ref('images/restricted');

}

export default Firebase;